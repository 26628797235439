<template>
  <div>
    <v-container>
      <!-- VIP 等級切換 -->
      <v-row>
        <v-col
          cols="12"
          class="pr-0"
        >
          <v-btn
            color="success"
            @click="$emit('change-tab', 0)"
          >
            vip 專屬
          </v-btn>
          <!-- VIP TABS -->
          <swiper
            ref="vipTab"
            class="swiper"
            :options="vipTabsOption"
          >
            <swiper-slide
              v-for="(vip, index) in showVipItem"
              :key="vip._id"
              class=" rounded vip-tab "
              :class="[
                activeIndex === index
                  ? 'vipCard1 white--text'
                  : 'inputBg subtitle--text',
              ]"
            >
              <div
                class="h-100 d-flex align-center justify-center cursor-pointer"
                @click="setVipSwiperActive(index)"
              >
                {{ vip.name }}
              </div>
            </swiper-slide>
          </swiper>
          4
          <!-- VIP CARD -->
          <swiper
            ref="vipCard"
            class="swiper swiper-no-swiping mt-3"
            :options="vipCardsOption"
          >
            <swiper-slide
              v-for="(vip, index) in showVipItem"
              :key="vip._id"
              class="rounded vip-card"
              :class="[
                index < 7 - 1 ? 'vipCard1' : 'vipCard2',
                {
                  vipCard3: index > 8 - 1,
                },
              ]"
            >
              <div>
                <img
                  class="p-absolute vip-card__icon"
                  :src="baseImgUrl + vip.icon"
                  width="36"
                >
                <span
                  class="p-absolute font-weight-bold vip-card__title card1--text"
                >
                  {{ vip.name }}
                </span>
              </div>

              <div class="p-absolute vip-card__description w-100 white--text">
                <v-container>
                  <v-row>
                    <v-col cols="2" />
                    <!-- 累積存款 -->
                    <v-col
                      cols="5"
                      class="text-left caption px-0 py-0"
                    >
                      {{ $t('myCenter.accumulatedDeposit') }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-right subtitle-2 font-weight-medium px-0 py-0"
                    >
                      {{ vip.deposit_amount }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" />

                    <!-- 流水要求 -->
                    <v-col
                      cols="5"
                      class="text-left caption px-0 py-0"
                    >
                      {{ $t('myCenter.turnoverRequire') }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-right subtitle-2 font-weight-medium px-0 py-0"
                    >
                      {{ vip.bet_amount }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" />

                    <!-- 保級流水 -->
                    <v-col
                      cols="5"
                      class="text-left caption px-0 py-0"
                    >
                      {{ $t('myCenter.threeMonthsForRelegationFlow') }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="text-right subtitle-2 font-weight-medium px-0 py-0"
                    >
                      {{ vip.min_bet_amount }}
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </swiper-slide>
          </swiper>
        </v-col>
      </v-row>

      <!-- Bonus 獎金領取 -->
      <v-row class="bonus px-3 pb-3">
        <!-- 升级奖金 -->
        <v-col
          :cols="activeIndex > 3 ? 4 : 6"
          class="px-1 pt-1"
        >
          <div
            class="card1 bonus__item rounded d-flex flex-column justify-center align-center text-center px-2"
          >
            <div class="caption title--text font-weight-medium">
              {{ $t('vip.upgradeBonus') }}
            </div>
            <div
              v-if="activeVipData"
              class="text-h6 secondary--text"
            >
              {{ activeVipData.upgrade_bonus }}
            </div>
          </div>
        </v-col>

        <!-- 每月红包 -->
        <v-col
          :cols="activeIndex > 3 ? 4 : 6"
          class="px-1 pt-1"
        >
          <div
            class="card1 bonus__item rounded d-flex flex-column justify-center align-center text-center px-2"
          >
            <div
              class="caption title--text font-weight-medium"
              :class="{ 'pt-4': btnStatus.redpacket }"
            >
              {{ $t('vip.monthlyDiscountRedEnvelope') }}
            </div>
            <div
              v-if="activeVipData"
              class="text-h6 secondary--text"
            >
              {{ activeVipData.monthly_bonus }}
            </div>

            <v-spacer v-if="btnStatus.redpacket" />

            <!-- 立即領取 每月红包 BTN -->
            <div
              v-if="btnStatus.redpacket"
              class="bonus__item-btn primary w-100 rounded-b"
            >
              <v-btn
                class="btnText--text caption  font-weight-medium"
                text
                block
                height="24"
                @click="getVipBonus('redpacket', activeIndex + 1)"
              >
                {{ $t('vip.getIt') }}
              </v-btn>
            </div>
          </div>
        </v-col>

        <!-- 生日礼金 -->
        <v-col
          v-show="activeIndex > 3"
          cols="4"
          class="px-1 pt-1"
        >
          <div
            class="card1 bonus__item rounded d-flex flex-column justify-center align-center text-center px-2"
          >
            <div
              class="caption title--text font-weight-medium"
              :class="{ 'pt-4': btnStatus.redpacket }"
            >
              {{ $t('myCenter.birthdayBonus') }}
            </div>
            <div
              v-if="activeVipData"
              class="text-h6 secondary--text"
            >
              {{ activeVipData.birth_bonus }}
            </div>

            <v-spacer v-if="btnStatus.redpacket" />

            <!-- 立即領取 生日礼金 -->
            <div
              v-if="btnStatus.birthday"
              class="bonus__item-btn primary w-100 rounded-b"
            >
              <v-btn
                class="btnText--text caption  font-weight-medium"
                text
                block
                height="24"
                @click="getVipBonus('birthday', activeIndex + 1)"
              >
                {{ $t('vip.getIt') }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- 豪禮贈送 -->
      <v-row
        v-show="activeVipData.extra_bonus"
        class="mt-0"
      >
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-divider class="divider" />
        </v-col>

        <v-col
          cols="3"
          class="d-flex align-center subtitle-1 title-text font-weight-medium"
        >
          {{ $t('vip.prize') }}
        </v-col>

        <v-col
          cols="9"
          class="d-flex align-center secondary--text"
        >
          <div class="caption font-weight-medium">
            {{ activeVipData.extra_bonus }}
          </div>
          <div v-show="btnStatus.bonus_extra">
            <v-btn
              min-width="52"
              height="28"
              color="primary"
              class="ml-6 btnText--text caption font-weight-medium"
              @click="getVipExtra(activeIndex + 1)"
            >
              {{ $t('global.application') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- vip n 優惠說明 -->
      <v-row
        v-if="activeVipData"
        class="bonus-2 px-3"
      >
        <v-col
          cols="12"
          class="subtitle-1 title-text font-weight-medium px-1 pt-0"
        >
          <v-divider class="mb-2 divider" />
          {{ activeVipData.name + ' ' + $t('vip.discountInfo') }}
        </v-col>

        <!-- 最低转账 -->
        <v-col
          cols="6"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded d-flex flex-column align-center justify-center bonus-2__item"
          >
            <div class="bonus__times">
              <span class="primary--text text-h6">
                {{ activeVipData.rebate_min_transfer }}
              </span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('vip.minimumTransfer') }}
            </div>
          </div>
        </v-col>

        <!-- 红利比例 -->
        <v-col
          cols="6"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded d-flex flex-column align-center justify-center bonus-2__item"
          >
            <div class="bonus__times">
              <span class="primary--text text-h6">
                {{ activeVipData.rebate_bonus_percent }}
              </span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('vip.dividends') }}
            </div>
          </div>
        </v-col>

        <!-- 最高奖金 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded d-flex flex-column align-center justify-center bonus-2__item text-center"
          >
            <div class="bonus__times">
              <span class="primary--text text-h6">
                {{ activeVipData.rebate_max }}
              </span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('vip.maximunBouns') }}
            </div>
          </div>
        </v-col>

        <!-- 次数限制 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded d-flex flex-column align-center justify-center bonus-2__item text-center"
          >
            <div class="bonus__times">
              <span class="primary--text text-h6">
                {{ $t(`global['${activeVipData.rebate_frequency}']`) }}
              </span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('vip.timesLimit') }}
            </div>
          </div>
        </v-col>

        <!-- 流水倍数 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card1 rounded d-flex flex-column align-center justify-center bonus-2__item text-center"
          >
            <div class="bonus__times">
              <span class="primary--text text-h6">
                {{ activeVipData.rebate_multiple }}
              </span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('vip.turnoverMultiple') }}
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="px-0 pb-0"
        >
          <v-divider class="divider" />
        </v-col>
      </v-row>

      <!-- 返水比例 table -->
      <v-row class="px-1">
        <v-col
          cols="12"
          class="subtitle-1 title-text font-weight-medium px-3 pt-2"
        >
          {{ $t('myCenter.vipTurnover') }}
        </v-col>

        <v-col cols="12">
          <v-simple-table
            v-if="activeVipData"
            dense
            class="card1"
          >
            <thead class="game">
              <tr>
                <th
                  class="px-1 py-1 text-center btnText--text primary rounded-tl w-41"
                >
                  {{ $t('global.level') }}
                </th>
                <!-- game name -->
                <th
                  v-for="game in activeVipData.rebates"
                  :key="game.name"
                  class="px-1 text-center title--text game__name "
                >
                  <div class="bacground primary w-100 h-100" />
                  <span class="p-relative">
                    {{ game.name }}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="item in vipList"
                :key="item._id"
              >
                <!-- VIP -->
                <td class="caption secondary--text px-2 text-center">
                  {{ item.code === 'vip0' ? 'VIP0' : item.name }}
                </td>

                <!-- value -->
                <td
                  v-for="game in item.rebates"
                  :key="game.name"
                  class="text-center caption title--text px-1"
                >
                  {{ game.value }}%
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>

      <!-- 提款額度 table -->
      <v-row class="px-1">
        <v-col
          cols="12"
          class="subtitle-1 title-text font-weight-medium"
        >
          <v-divider class="mb-2 divider" />
          {{ $t('vip.vipWithdrawLimit') }}
        </v-col>

        <!-- TABLE -->
        <v-col cols="12">
          <v-simple-table
            v-if="activeVipData"
            dense
            class="card1"
          >
            <thead class="withdrawals">
              <tr>
                <th
                  class="px-1 py-1 text-center btnText--text primary rounded-tl w-41"
                >
                  {{ $t('global.level') }}
                </th>

                <th
                  v-for="(text, index) in withdrawalsTableHead"
                  :key="index"
                  class="px-1 text-center title--text withdrawals__name "
                >
                  <div class="bacground primary w-100 h-100" />
                  <span class="p-relative">
                    {{ text }}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="item in vipList"
                :key="item._id"
              >
                <!-- VIP -->
                <td class="caption  secondary--text px-2 text-center">
                  {{ item.code === 'vip0' ? 'VIP0' : item.name }}
                </td>

                <!-- value -->
                <td class="text-center caption title--text px-1">
                  {{ item.withdrawal_daily_count }}
                  <span>{{ $t('myCenter.times') }}</span>
                </td>

                <td class="text-center caption title--text px-1">
                  {{ item.withdrawal_daily_amount }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
      :height="140"
    >
      <div
        slot="text"
        class="px-6"
      >
        {{ dialog.text }}
      </div>

      <v-btn
        slot="btn"
        color="primary"
        text
        @click="closeDialog"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import detailsMixin from './mixins/details'

export default {
  mixins: [detailsMixin],
}
</script>

<style lang="scss" scoped>
.vip-tab {
	width: 52px;
	height: 36px;
}

.vip-card {
	position: relative;
	width: 280px;
	height: 152px;

	&__icon {
		top: 24px;
		left: 24px;
	}

	&__title {
		bottom: -30px;
		left: 0;
		font-size: 72px;
		opacity: 0.1;
	}

	&__description {
		top: 29px;
	}
}

.bonus,
.bonus-2 {
	&__item {
		position: relative;
		height: 100px;
	}
}

.bonus__item-btn{
  position: absolute;
  bottom: 0;
}

.game,
.withdrawals {
	&__name {
		position: relative;

		> .bacground {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.3;
		}

		&:last-child {
			> .bacground {
				border-top-right-radius: 4px;
			}
		}
	}
}

.w-41 {
	width: 41px;
}
</style>
