<template>
  <div
    id="vip"
    class="pt-56 h-100dvh over-flow-y-scroll"
  >
    <!-- Toolbar -->
    <component :is="curryToolbar">
      <!-- 條款與規則 btn -->
      <v-btn
        slot="btn"
        to="vip-rules"
        text
        class="caption"
        :class="[layout === 'template1' && layout === 'template2' ? 'title--text' : 'white--text']"
      >
        {{ $t('myCenter.termsnRules') }}
      </v-btn>
    </component>

    <!-- TABS -->
    <template v-if="showTabTemplate">
      <v-tabs
        v-model="activeTab"
        height="40"
        background-color="transparent"
        color="primary"
        grow
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          class="subtitle-1 font-weight-medium"
        >
          {{ tab }}
        </v-tab>
      </v-tabs>

      <v-divider class="divider" />
    </template>

    <!-- COMTENT -->
    <component
      :is="activeComponent"
      :vip-list="vipList"
      @change-tab="changeTabActive"
    />

    <!-- DIALOG -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'

import ToolbarTemplate1 from '@/components/base/template/ToolbarTemplate1'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3'
import ToolbarTemplate4 from '@/components/base/template/ToolbarTemplate4'

import { curryTemplate } from '../../../utils/template'

import Prerogative from './Prerogative'
import PrerogativeTemplate3 from './PrerogativeTemplate3'
import PrerogativeTemplate4 from './PrerogativeTemplate4'

import Details from './Details'
import DetailsTemplate3 from './DetailsTemplate3'
import DetailsTemplate4 from './DetailsTemplate4'

export default {
  components: {
    ToolbarTemplate1,
    Prerogative,
    Details,
  },

  data: () => ({
    activeTab: 0, // 特權 0 || 詳情 1
    vipList: [], // 所有 vip 資料
  }),

  computed: {
    ...mapState({
      nowVip: state => state.vip.now,
      nextVip: state => state.vip.next,
      vipProgress: state => state.vip.progress,
    }),

    ...mapGetters(['layout']),

    tabs() {
      return [
        this.$t('myCenter.myPrivileges'),
        this.$t('myCenter.moreVipDetail'),
      ]
    },

    curryToolbar() {
      const templateObj = {
        template1: ToolbarTemplate1,
        template3: ToolbarTemplate3,
        template4: ToolbarTemplate4,
      }

      return curryTemplate(this.layout, templateObj)
    },

    // 當前顯示畫面
    activeComponent() {
      const prerogativeTemplateObj = {
        template1: Prerogative,
        template3: PrerogativeTemplate3,
        template4: PrerogativeTemplate4,
      }

      const detailsTemplateObj = {
        template1: Details,
        template3: DetailsTemplate3,
        template4: DetailsTemplate4,
      }

      const prerogativeCurryTemplate = curryTemplate(this.layout, prerogativeTemplateObj)
      const detailsCurryTemplate = curryTemplate(this.layout, detailsTemplateObj)

      return this.activeTab === 0 ? prerogativeCurryTemplate : detailsCurryTemplate
    },

    showTabTemplate() {
      const showTemplate = ['template1', 'template2', 'template5']

      return showTemplate.includes(this.layout)
    },
  },

  mounted() {
    this.getVipList()
    this.set_toolbar_title(this.$t('myCenter.myVip'))
    this.getUserVipData()
  },

  methods: {
    ...mapActions(['get_user_vip', 'set_toolbar_title', 'get_vip_list']),

    getUserVipDataSuccess() {},
    getUserVipDataFail() {},

    /**
     * 取得目前使用者 vip 資料 (目前/下一等級/進度) 存 vuex
     * @date 2021-04-19
     */
    async getUserVipData() {
      const res = await this.get_user_vip()
      resStatus(res, this.getUserVipDataSuccess, this.getUserVipDataFail)
    },

    /**
     * 切換 tab 頁籤 for component
     * @date 2021-04-19
     * @param {Number} tab  特權 0 || 詳情 1
     */
    changeTabActive(tab) {
      this.activeTab = tab
    },

    /**
     * 取得所有 vip 資料 (成功)
     * @date 2021-04-19
     * @param {object} {vip} data.vip vip 所有資料
     */
    getVipListSuccess({ vip }) {
      this.vipList = vip
    },

    /**
     * 取得所有 vip 資料
     * @date 2021-04-19
     */
    async getVipList() {
      const res = await this.get_vip_list()
      resStatus(res, this.getVipListSuccess)
    },
  },
}
</script>

<style lang="scss" scoped></style>
