<template>
  <div>
    <v-container>
      <v-row class="rounded ma-0 card2 mx-1">
        <!-- 當前等級 -->
        <v-col
          cols="12"
          class="px-0 pb-0 pt-0"
        >
          <div class="d-flex px-4">
            <div class="font-weight-bold title--text d-flex">
              <img
                class=""
                :src="baseImgUrl + nowVip.icon"
                width="36"
                height="56"
              >
              <div class="mt-3 ml-2">
                <span>
                  {{ $t('vip.currentLevel') }}
                </span>
                <span class="primary--text">{{ nowVip.code && nowVip.code.toUpperCase() }}</span>
              </div>
            </div>
            <v-spacer />
            <div class="d-flex align-end">
              <v-btn
                :color="theme === 'light' ? 'primary' : 'secondary'"
                :outlined="theme === 'dark' ? true : null"
                @click="$emit('change-tab', 1)"
              >
                {{ $t('vip.details') }}
              </v-btn>
            </div>
          </div>
        </v-col>

        <!-- 等級進度條 -->
        <v-col
          cols="12"
          class="pt-0"
        >
          <div class="text-center caption title--text font-weight-medium w-100 vip-progress">
            {{ vipProgress.progress }}%
          </div>
          <div class="userInfo__progress vip d-flex align-center py-0">
            <!-- 目前等級 icon -->
            <span class="primary--text font-italic">{{ nowVip.code && nowVip.code.toUpperCase() }}</span>
            <!-- 進度條 -->
            <v-progress-linear
              class="mx-2 vip__progress"
              rounded
              :value="vipProgress.progress"
              color="primary"
              height="6"
            />
            <!-- 下一等級 icon -->
            <span
              v-if="nextVip"
              class="primary--text font-italic"
            >{{ nextVip.code && nextVip.code.toUpperCase() }}</span>
          </div>
        </v-col>

        <v-col
          v-if="nextVip"
          cols="12"
          class="text-left subtitle-2 pt-0 caption px-6"
        >
          <ul class="pl-0 title--text">
            <!-- 當前存款 -->
            <li>
              <div class="d-flex">
                <div class="title--text">
                  {{ $t('myCenter.nowDeposit') }}
                  {{ `(${$t('myCenter.dollar')})` }}
                </div>
                <v-spacer />
                <div class="primary--text subtitle-1 font-weight-bold">
                  {{ vipProgress.deposit_amount }}
                </div>
              </div>

              <!-- 當前/升級 -->
              <div class="hint--text text-right">
                ({{ vipProgress.deposit_amount }} /
                {{ nextVip.deposit_amount }} )
              </div>
            </li>
            <!-- 當前流水 -->
            <li>
              <div class="d-flex">
                <div class="title--text">
                  {{ $t('myCenter.nowTurnover') }}
                  {{ `(${$t('myCenter.dollar')})` }}
                </div>
                <v-spacer />
                <div class="subtitle-1 primary--text font-weight-medium">
                  {{ vipProgress.bet_amount }}
                </div>
              </div>
              <!-- 當前/升級 -->
              <div class="hint--text text-right">
                ({{ vipProgress.bet_amount }} / {{ nextVip.bet_amount }} )
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>

      <!-- Bonus -->
      <v-row class="px-3 bonus mt-2">
        <!-- 每日提款次数 -->
        <v-col
          cols="6"
          class="px-1 py-1 caption"
        >
          <div
            class="card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="title--text text-h6">
                {{ nowVip.withdrawal_daily_count }}
              </span>
              <span> {{ $t('myCenter.times') }}</span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('myCenter.withdrawalDailyCount') }}
            </div>
          </div>
        </v-col>

        <!-- 每日提款额度 -->
        <v-col
          cols="6"
          class="px-1 py-1 caption"
        >
          <div
            class="card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="title--text text-h6">
                {{ nowVip.withdrawal_daily_amount }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              {{ $t('myCenter.withdrawalDailyLimit') }}
            </div>
          </div>
        </v-col>

        <!-- 升级礼金 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="title--text text-h6">
                {{ nowVip.upgrade_bonus }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              <a
                href="javascript:;"
                class="link--text"
                @click="$emit('change-tab', 1)"
              >
                {{ $t('myCenter.upgradeBonus') }}
              </a>
            </div>
          </div>
        </v-col>

        <!-- 生日礼金 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="title--text text-h6">
                {{ nowVip.birth_bonus }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              <a
                href="javascript:;"
                class="link--text"
                @click="$emit('change-tab', 1)"
              >
                {{ $t('myCenter.birthdayBonus') }}
              </a>
            </div>
          </div>
        </v-col>

        <!-- 每月免费红包 -->
        <v-col
          cols="4"
          class="px-1 py-1 caption"
        >
          <div
            class="card2 rounded text-center d-flex flex-column align-center justify-center h-80px px-2"
          >
            <div class="bonus__times">
              <span class="title--text text-h6">
                {{ nowVip.monthly_bonus }}
              </span>
              <span> {{ $t('myCenter.dollar') }}</span>
            </div>

            <div class="bonus__description titile--text">
              <a
                href="javascript:;"
                class="link--text"
                @click="$emit('change-tab', 1)"
              >
                {{ $t('myCenter.monthlyBonus') }}
              </a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- 返水比例 -->
    <div class="rebate-ratio px-4 py-1 bg--text font-weight-bold mt-6">
      {{ $t('myCenter.vipTurnover') }}
    </div>

    <v-container class="mb-3">
      <!-- 返水項目 -->
      <v-row class="px-4 mt-0 card1 mx-1 rounded">
        <v-col
          v-for="game in nowVip.rebates"
          :key="game.name"
          cols="4"
          class="caption"
        >
          <div
            class="rounded d-flex flex-column align-center justify-center h-80px"
          >
            <div class="rebates__percentage">
              <span class="title--text text-h6"> {{ game.value }}% </span>
            </div>

            <div class="rebates__description subtitle--text">
              {{ game.name }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import prerogativeMixin from './mixins/prerogative'

export default {
  mixins: [prerogativeMixin],
}
</script>

<style lang="scss" scoped>
.vip {
	&__next {
		filter: grayscale(90%);
	}
}
.h-80px {
	height: 80px;
}
.vip-info {
  background: #ddd;
}
.vip-progress {
  transform: translateY(10px);
}
.rebate-ratio {
  display: inline-block;
  background: linear-gradient(270deg, #BE5A4F -6.94%, #C03111 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px 0px 4px;
}
</style>
